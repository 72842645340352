footer {
  position: relative;
  overflow: hidden;
  color: 'white';
  /* background-color: #282834; */
  background: linear-gradient(to left top, #140E38, #194089);
  
}
.pattern-left {
  position: absolute;
  top: 70px;
  left: -40px;
  width: 180px;
  transform: rotate(90deg);
}
.pattern-right {
  position: absolute;
  bottom: 10px;
  right: -55px;
  width: 180px;
}
.social img {
  width: 25px;
  margin: 0 10px;
}

footer a,
footer a:hover {
  color: white;
  text-decoration: none;
  margin: 0 5px;
}

.border-top-footer h6 {
  color: white;
}

.border-top-footer {
  border-top: 1px solid #6b6495;
}

@media (max-width: 425px) {
  nav.mobile-nav {
    margin-top: 5px !important;
    display: grid !important;
    font-size: 14px !important;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-row-gap: 20px !important;
    grid-column-gap: 20px !important;
  }

  .pt-4.pb-4.d-flex {
    flex-direction: column;
  }

  .border-top-footer {
    text-align: center;
    flex-direction: column;
  }

  .border-top-footer.pt-5 {
    padding-top: 24px !important;
  }

  .social {
    margin-top: 15px;
  }
  .mb-cust{
    margin-bottom: 3rem !important;
  }
  .mw-100{
    width: 100% !important;
  }
  .dm-flex{
    display: flex!important;
  }
  .pl-fcust{
    padding-left: 3.5rem!important;
  }
}
