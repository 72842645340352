

html{
  scroll-behavior:smooth;
}
td h5{
  color: black;
}
.scrollersB{
  opacity: 0;
  transform: translateY(100%);
}
.scrollersT{
  opacity: 0;
  transform: translateY(-100%);
}
.scrollersL{
  opacity: 0;
  transform: translateX(-100%);
}
.scrollersR{
  opacity: 0;
  transform: translateX(100%);
}

/* -------------FONTS------------------------- */

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}
/* #top-div{
  animation: start-left 1s ease-out;
} */
.f-18.p-medium,.f-22.p-medium{
  color: black;
}
.p-medium{
  font-family: Poppins-Medium;
  color: white;
}

.color-white{
  color: white !important
}

.p-bold{
  font-family: Poppins-Bold
}
.p-bold.p-2{
  color: white;
  
}

.p-regular{
  font-family: Poppins-Regular
}

.p-extra-bold{
  font-family: Poppins-ExtraBold
}

.p-semi-bold{
  font-family: Poppins-SemiBold
}

.p-light{
  font-family: Poppins-Light
}

.hide {
  visibility: hidden;
}



.fixed {
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  background:#194089;
  animation: smoothScroll 1s forwards;
  z-index: 500000;
}

.mobile-nav-bottom{
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}


@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

.dummy-icon{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #91929A;
  display: inline-block;
  margin: inherit 6px;
}

body{
  /* overflow-x: hidden; */
  font-family: Poppins-Regular !important ;
  /* background: #EFEFF6; */
  /* background: linear-gradient(to right,#140E38,#194089); */
 
    background: linear-gradient(to right,#140E38,#194089);
  
  font-size: 18px;
}
.content-body{
  padding-bottom: 50px;
  /* background: linear-gradient(to right,#140E38,#194089); */
  background: #f8f9fa;
}

.sticky-top{
  width: 100%;
  /* background: #EFEFF6; */
  background: linear-gradient(to right,#140E38,#194089);
}

nav.navbar{
  margin-top: -20px;
  margin-bottom: -20px;
 padding: 7px 0;
}

.nav-link{
}
.p-3.active.text-capitalize.userName.nav-link{
  color: white;
}

ul.navbar-nav li.nav-item a.nav-link .active{
  color: #302D3A;
}




.container{
  
  /* margin : 0 auto; */
  padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
  /* max-width: 1200px; */
}
.container.body-2{
  background-color: #f8f9fa;
  
}

.container.body-2 h1{
  padding: 55px 0;
}

.reward-header{
  display: inline-block;
  width: 65%;
  text-align:end;
}


.txt-center{
  text-align: center;
}



.bg-shade{
  padding-top: 15px;
  padding-bottom: 15px;

  background: linear-gradient(to right,#140E38,#194089);
  width: 100%;
  font-size: 18px;
  justify-content: center;
}

.f-12{
  font-size: 12px;
}

.f-22{
  font-size: 22px ;
}

.f-16{
  font-size: 16px ;
}

.f-14{
  font-size: 14px ;
}

.f-24{
  font-size: 24px ;
}

.f-18{
  font-size: 18px ;
}

.f-20{
  font-size: 20px ;
}

.f-40{
  font-size: 40px;
}

.back-w{
  background: white!important;
}

.bg-white{
  background: white;
  position: relative;
  top: 5rem;
  border-radius: 4px;
  line-height: 1;
  padding: 22px;
}

.bg-shade h1{
  color: white;
  position: relative;
  top: 3rem;
  /* margin-bottom: 3rem; */
}

.m-show{
  display: none ;
}

.list-group-horizontal .list-group-item{
  border: none;
}


.btn-redeem{
 /* background: #F56962; */
 background-image: linear-gradient(40deg, rgb(191, 104, 230) 20%, rgb(158, 72, 205) 51%, rgb(191, 104, 230) 90%);
box-shadow: rgb(158, 72, 205) 0px 0px 12px 0px;
}

.widget-rule{
  border-top: 1px solid #cac6d4;
  height: 15px !important;
  background: none;
}

.widget-top-align{
  position: relative;
  top: 25% !important;
  font-size: 18px;
}

.tile{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #EFEFF6;
}

.tile.my-tile{
  width: 230px !important;
  height: 180px !important;
}

.tile.col-md-3.col-6{
  padding: 20px;
  margin: 5px;
  flex: 1 1;
}

#UPI, #Paytm, #PhonePe, #Bank :hover {
  cursor: pointer;
}

.body-2{
  padding: 52px 0 ;
}

.bg-shade.body-2{
  padding: 70px 18px 18px;
}

td h6{
  margin-top: 15px;
}

.card{
  padding: 10px;
  /* width: 360px; */
  
}

.card-body{
  text-align: center;
  padding: .5rem
}

td p {
  margin: 0px;
}

.cover-auto{
  border: 4px solid #3b0031;
  border-radius: 4px;
  width: 100px;
  margin: 12px;
  background: lightgrey;
}
.card-text{
  font-size: 16px;
  text-align: justify;
}
.img-description{
  font-size: 12px;
  /* display: inline-block; */
}
.card-title{
  font-size: 22px;
  line-height: 1.25
}
.img-circle{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: lightgrey;
  display: inline-block;
}


.c-grid{
  padding: 20px;
  margin: 5px;
  text-align: center;
  font-size: 16px;

}

.c-grid-content{
  font-size: 24px;
  color:black;
  line-height:1.3;
}

.w-75, .w-25{
  display: inline-block;
}

.card-footer{
  padding: 0 0 0 0;
  background:#EFEFF6 ;
}

.card-body p a{
  color: #00008B;
}

.btn-link, .btn-link:hover{
  /* background: #F56962; */
  background-image: linear-gradient(40deg,#BF68E6 20%,#9E48CD 51%,#BF68E6 90%);
  box-shadow: 0 0 12px 0 #9e48cd;
  border-radius: 3px;
  text-decoration: none;
  color: #302d3a ;
}

.page-r.reward-header.m-t-c{
  width: 60% !important;
}

.floating-div{
  /* height: 90px; */
  margin: 15px 0px;
  border-radius: 5px;
}
.floating-div .row{
  padding: 26px 5px;
}

.body-3{
  padding-top: 50px;
  border-radius: 4px;
  background-color: white;
  color: black;
  text-align: center;
  padding-bottom: 52px
}
.help{
  padding-top: 31px;
  font-size: 24px;
  color: #5f5982;
}

.btn-xl{
  padding: 11px;
  margin: 1%
}
.bg-shade h3{
  color: white;
}

a.footer-link,a.footer-link:hover{
  text-decoration: none;
  color:  #91929A !important;
  cursor: pointer;
}
.text-muted{
  font-size: 14px;
}


/* .c-grid .text-muted{
  font-size: 11px;
} */

.text-muted.social-icons{
  font-size: 16px
}

i {
  margin: auto 10px;
}

.overflow-auto{
  overflow: hidden !important;
}

.mt-major{
  margin-top: 135px !important;
}

.error{
  background-image: linear-gradient(40deg, rgb(191, 104, 230) 20%, rgb(158, 72, 205) 51%, rgb(191, 104, 230) 90%);
  box-shadow: rgb(158, 72, 205) 0px 0px 12px 0px;
  color:white;
}
/* .showL{
  animation: start-left 1s ease-out;
}
.showR{
  animation: start-right 1s ease-out;
}
.showT{
  animation: start-top 1s ease-out;
} */
.showB{
  transition: transform 1s ease-out,
  opacity 1s ease-out;
  transform: translateY(0%);
  opacity: 1;
  /* animation: start-bottom 1s ease-out; */
}
.showL{
  transition: transform 1s ease-out,
  opacity 1s ease-out;
  opacity: 1;
  transform: translateX(0%);
  /* animation: start-bottom 1s ease-out; */
}
.showR{
  transition: transform 1s ease-out,
  opacity 1s ease-out;
  opacity: 1;
  transform: translateX(0%);
  /* animation: start-bottom 1s ease-out; */
}
.showT{
  transition: transform 1s ease-out,
  opacity 1s ease-out;
  opacity: 1;
  transform: translateY(0%);
  /* animation: start-bottom 1s ease-out; */
}

@media (max-width:900px) {
  .fixed{
    position: relative;
  }
  .m-hidden{
    display: none !important;
  }

  .m-show{
    display: block;
  }

  .bg-shade{
    text-align: center;
  }

  .footer-list{
    text-align: left;
  }

  .bg-shade h1{
    position: static;
    padding: 20px 0 45px;
  }
  nav.navbar.navbar-expand.navbar-light.bg-\#.fixed-bottom {
    /* background: #EFEFF6 !important; */
    background: linear-gradient(to right,#140E38,#194089) !important;
    padding: 5px 2px 0px;

  }
  .overflow-auto{
    overflow: auto !important;
  }
}

@media (min-width:360px) and (max-width:500px) {

  .fixed{
    position: relative;
  }

  body{
    background: white
  }
  nav .container-fluid{
    padding: 0
  }
  .body-2{
    padding: 14px;
  }
  .card{
    margin: 4px 0;
  }
  
  .container.body-2 h1{
    text-align: center;
    padding: 55px 0px 10px;
    font-size: 28px!important
  }
  .c-grid-content{
    font-size: 20px;
  }
  .reward-header{
    width: 100%;
    text-align: left;
  }
  .col-6.f-22, .col-2{
    font-size: 14px !important;
    line-height: 1.2;
  }
  .help{
    font-size: 18px;
  }
  .bg-shade h3{
    font-size: 15px !important;
    text-align: left;
  }
  .col-6.col-sm-3 .footer-list{
    font-size: 16px !important;
    text-align: left;
  }
  .social-icons{
    text-align: right;
    margin-bottom: 2%;
  }
  .m-show .row .col{
    margin: 3px;
  }
  .m-show .row .col .c-grid-content{
    font-size: 18px;
  }
  .m-show .row .col p{
    line-height: 1
  }
  .help{
    font-size: 16px;
  }

  .m-show .container{
    text-align: center;
  }

  .floating-div .row{

  }

  .m-show button{
    margin-top: 40px ;
  }
  .card{

    margin: 15px 2px;
  }
  h1{
    font-size: 30px !important
  }
  .m-t-c{
    text-align: center;
  }
  .w-50{
    width: 100% !important;
  }
  .m-cont .f-22{
    font-size: 18px !important;
  }
  h2.m-header-success.f-40{
    font-size: 30px;
    text-align: center;
  }
  .page-r.reward-header.m-t-c{
    width: 100% !important;
  }
  .mobile-footer-links .col-6.col-sm-2.px-1, .mobile-footer-links .col-6.col-sm-2.px-4{
    padding: 0 15px !important;
  }
  nav.navbar{
    padding:5px 0 10px;
  }

  .mt-justify{
    text-align: justify;
  }
  .mt-major{
    margin-top: .5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mb-btn{
    width: 100% !important
  }
  div.row.mx-0 div.col-md-4.col-12{
    padding-left: 0 !important;
    padding-right: 0!important
  }
}


/* loader */
*{
  box-sizing: border-box;
}

.loader-container {
  z-index: 1111;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background: #FFFFFF;
  opacity: 1;
}

.kinetic{
  position: relative;
  width: 40px;
  height: 40px;
  background: url(./images/loader-icon.png) no-repeat center;
  background-size: contain;
  border-radius: 50%;
}

.kinetic::before {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 2.5px solid #194089;
  border-right: 2.5px solid #194089;
  border-top: 2.5px solid #FFFFFF;
  border-bottom: 2.5px solid #FFFFFF;
  border-radius: 50%;
  animation: rotate-left 1.5s linear infinite;
}

.kinetic::after {
  content: '';
  position: absolute;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 2.5px solid #FFFFFF;
  border-right: 2.5px solid #FFFFFF;
  border-top: 2.5px solid #194089;
  border-bottom: 2.5px solid #194089;
  border-radius: 50%;
  animation: rotate-right 1.5s linear infinite;
}

@keyframes rotate-right{
  0%{
      transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
      transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes rotate-left{
  0%{
      transform: translate(-50%, -50%) rotate(360deg);
  }
  100%{
      transform: translate(-50%, -50%) rotate(0deg);
  }
}
/* animations */
/* @keyframes start-left{
  0%{
    opacity: 0;
    transform: translateX(-100%);
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes start-right{
  0%{
    opacity: 0;
    transform: translateX(100%);
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes start-bottom{
  0%{
    opacity: 0;
    transform: translateY(100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes start-top{
  0%{
    opacity: 0;
    transform: translateY(-100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
} */